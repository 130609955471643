import React, { useState, useEffect } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { getUserDetails, updateUserDetails } from '../../services/admin';
import { Container, Card, Button ,Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify'
import { useLocation } from 'react-router-dom';

export default function RequestDetails() {

    const { _id } = useParams();
    const [userData, setUserData] = useState({});
    const history = useHistory();
    const location = useLocation();
    const remarks = location.state.remarks;
    
    const getData = async () => {
        await getUserDetails(_id)
            .then((res) => {
                if (res.status == 200) {
                    console.log(res.data, "User_data");
                    toast.success("Success");
                    setUserData(res.data)
                }
            })
            .catch(e => {
                console.error(e.response.data)
                toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
            })
    }

    const onUpdateClick = async () => {
        await updateUserDetails(userData, _id)
            .then((res) => {
                if (res.status == 200) {
                    toast.success("Data Updated Successfully");
                    history.goBack();
                }
            })
            .catch(e => {
                console.error(e.response.data)
                toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
            })
    }

    useEffect(() => {
        getData();
    }, [])

    useEffect(() => {
        console.log(userData , "userData")
    }, [userData])    
    return (
        <>
            <div className="bg-bg-primary" style={{ minHeight: '100vh' }}>
                <Container className='pt-4'>
                    <Card className="p-3 border-0 rounded">
                        <FontAwesomeIcon icon={faArrowLeft} onClick={() => history.goBack()} />

                        <div className="mt-4">
                            <h5 className="font-weight-bold">
                                <div> Candidate Info (Reg. No. {userData._id})</div>
                            </h5>
                        </div>

                        <div className="font-weight-bold">
                            <h5>
                                <span className="text-muted">
                                    Remarks: {" "} {remarks}
                                </span>
                            </h5>
                        </div>


                        <div className='mt-2'>
                            <Form.Label htmlFor="name">Name</Form.Label>
                            <Form.Control
                                type="text"
                                id="name"
                                placeholder={userData.name}
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => {setUserData({...userData , name:e.target.value})}}
                            />
                        </div>

                        <div className='mt-2'>
                            <Form.Label htmlFor="email">Email</Form.Label>
                            <Form.Control
                                type="mail"
                                id="email"
                                placeholder={userData.email}
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => {setUserData({...userData , email:e.target.value})}}
                            />
                        </div>

                        <div className='mt-2'>
                            <Form.Label htmlFor="phone">Phone</Form.Label>
                            <Form.Control
                                type="number"
                                id="phone"
                                placeholder={userData.phone}
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => {setUserData({...userData , phone:e.target.value})}}
                            />
                        </div>

                        <div className='mt-2'>
                            <Form.Label htmlFor="alt-phone">Alternate Phone</Form.Label>
                            <Form.Control
                                type="number"
                                id="alt-phone"
                                placeholder={userData.alternatePhone}
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => {setUserData({...userData , alternatePhone:e.target.value})}}
                            />
                        </div>

                        <div className='mt-2'>
                            <Form.Label htmlFor="alt-phone">Alternate Phone</Form.Label>
                            <Form.Control
                                type="date"
                                id="alt-dob"
                                placeholder={userData.alternatePhone}
                                aria-describedby="passwordHelpBlock"
                                onChange={(e) => {setUserData({...userData , alternatePhone:e.target.value})}}
                            />
                        </div>

                        <div className="d-flex flex-row-reverse mt-3">
                            <Button variant="success" type="button" onClick={onUpdateClick}>
                                Update
                            </Button>
                        </div>
                    </Card>
                </Container>
            </div>
        </>
    )
}