import React from "react";

import { Table } from "react-bootstrap";

import moment from 'moment';


import "../../../assets/css/card/card.css";
import "./DataTable.css";
import { statusColors } from "../../../services/statusColors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router";

const DataTable = ({rows, showStatus, showApplicationNumber}) => {

  let history = useHistory();

  const statusColor = (status) =>{
    const color = statusColors(status);
    return (
      <span class={"badge badge-"+color}>{status}</span>
    )
  }

  const createDate = (date)=>{
    return moment(date).format("Do MMM YY")
  }

  return (
    <>
      <Table size="sm" responsive striped>
        <thead>
            
            <th>
                Name
            </th>
            <th>
              Email
            </th>
            <th>
                Reg No.
            </th>

            <th>
              Phone
            </th>
            <th>
                Created On
            </th>
            {showStatus?<th>Status</th>: " "}
            {showApplicationNumber? <th>Appl. No.</th> : " "}
            <th>
               Fee Status
            </th>
        </thead>
        <tbody>
          {rows.map((data,index)=>{
            return (
              <tr>
                  <td className="font-weight-bold">
                    {data.name}
                  </td>
                  <td>
                    {data.email}
                  </td>
                  <td>
                  {data.registerationNumber}
                  <FontAwesomeIcon icon={faEye} onClick={()=>history.push('/viewuser/'+data._id)}/>
                  </td>
                  <td>
                  {data.phone}
                  </td>

                  <td>
                  {createDate(data.createdAt)}

                  </td>
                    {showStatus?<td>{statusColor(data.status)}</td>: " "}
                    {showApplicationNumber ? <td className="fw-bold">{data.applicationNumber}</td> : " "}
                  
                  <td>
                  {data.feeStatus ? "Paid" : "Unpaid"}
                  </td>
              </tr>
            )
          })}
        </tbody>
      </Table>
    </>
  )
 
};
export default DataTable;

