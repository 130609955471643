import React, {useEffect} from "react";
import { useParams, useHistory } from "react-router";

// components
import XadmNavbar from "../Navbar/Navbar";
import SwitchComponent from "../SwitchComponent/SwitchComponent";

export default function SubComponent({selectedNav, topNavMenu, setNav}) {

    const {
        menu,
        submenu
    } = useParams();

    useEffect(()=>{
        for(let navmenu in topNavMenu){
          if(topNavMenu[navmenu].url == submenu){
            setNav(navmenu)
          }
        }
      },[])

    return (
        <>
            <XadmNavbar menuData={topNavMenu} selected={selectedNav} setNav={(value)=>setNav(value)} />
            <SwitchComponent component={topNavMenu[selectedNav].component}/>
        </>

    )
}
