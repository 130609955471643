import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { CSVDownload, CSVLink } from 'react-csv';

import { getUserByStatus } from '../../../services/admin';

import DataTable from '../DataTable/DataTable';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';


export default function VerifiedUsers() {
    const [userData, setUserData] = useState({
        users: []
    });
    const [selectedUserStatus, setUserStatus] = useState();

    const [downloadCsvFormat, setDownloadCsvFormat] = useState([]);

    const userStatuses = [
        {
            label: "Pending",
            value: "pending"
        },
        {
            label: "General Info",
            value: "geninfo"
        },
        {
            label: "Education Info",
            value: "eduinfo"
        },
        {
            label: "Uploads",
            value: "uploaded"
        },
        {
            label: "Verified Users",
            value: "verified"
        },
        {
            label: "Submitted",
            value: "submitted"
        }
    ]


    const createDownloadCSVFormat = (statusdata) => {
        let csvFileData;
        if (statusdata && statusdata.users.length > 0) {
            csvFileData = statusdata.users.map((data, index) => {
                return {
                    Name: data.name,
                    "Registration Number": data.registerationNumber,
                    Email: data.email,
                    Phone: data.phone,
                    Status: data.status,
                    "City 1": data.cityChoices[0] || "Not Selected",
                    "City 2": data.cityChoices[1] || "Not Selected",
                    "Date of Birth": data.dob ? moment(data.dob).format("Do MMMM YYYY") : "Not Available",
                    "Fee Amount": data.transactionDetails?(data.transactionDetails.currency+" "+String(data.transactionDetails.amount/100)):"0",
                    "Razorpay Transaction Id": data.transactionDetails? (data.transactionDetails.orderId):"NA",
                    "Trans. Date and Time": data.transactionDetails? moment(data.transactionDetails.updatedAt).format("Do MMMM YYYY hh:mm:ss a"):"NA",
                    
                }

            });
        }
        setDownloadCsvFormat(csvFileData);
    }


    const onMount = async () => {
        await getUserByStatus('verified')
            .then(res => {
                if (res.status === 200) {
                    toast.success("Fetched Records")
                    setUserData(res.data)
                }
            }).catch(e => {
                console.error(e.response.data)
                toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
            })
    }

    const changeUserStatus = async (status) => {
        setUserStatus(status.label);
        await toast.promise(getUserByStatus(status.value).then(res => {
            if (res.status === 200) {
                setUserData(res.data)
                createDownloadCSVFormat(res.data);
            }
            else {
                toast.error("Something went wrong")
            }
        }).catch(e => {
            console.error(e);
        }), {
            pending: "Fetching Records",
            success: 'Records Fetched 👌',
            error: 'Request Rejected 🤯'
        })
    }

    return (
        <>

            <Container className="mt-4 ">
                <Row>
                    <Col md={4}>
                        <Select
                            options={userStatuses}
                            onChange={changeUserStatus}
                        />
                    </Col>
                    <Col md={4}>
                        {downloadCsvFormat && downloadCsvFormat.length>0?
                        <CSVLink
                            data={downloadCsvFormat}
                            filename={selectedUserStatus + ".csv"}
                        >
                            <Button> Download CSV <FontAwesomeIcon icon={faDownload}/> </Button> 
                        </CSVLink>
                        : <Button variant="secondary" disabled>Download CSV <FontAwesomeIcon icon={faDownload}/> </Button>}
                    </Col>
                </Row>
                <Row className="bg-white mt-3 pt-2 rounded">
                    <Col>
                        {
                            userData && userData.users ?
                                <DataTable rows={userData.users} showStatus={false} showApplicationNumber={true} heading="User Data" /> : "No Data"

                        }
                    </Col>
                </Row>

            </Container>

        </>
    )
}