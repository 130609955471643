import makeRequest from "./makeRequest";
import urls from "./urls";

export async function getUsers(pageSize, pageNumber) {
    return await makeRequest(urls.getUsers + "?pageSize=" + pageSize + "&pageNumber" + pageNumber, 'get');
}

export async function getSubmittedUsers() {
    return await makeRequest(urls.getSubmittedUsers, 'get');
}

export async function getUserByStatus(status) {
    return await makeRequest(urls.getUserByStatus + "?status=" + status, 'get')
}

export async function getUserProfile(userId) {
    return await makeRequest(urls.getUserProfile + userId, 'get');
}

export async function getUsersStats() {
    return await makeRequest(urls.getUserStats, 'get');
}

export async function getCitiesData() {
    return await makeRequest(urls.getCenters, 'get');
}

export async function getCandidatesByCenters(center1, center2) {
    return await makeRequest(urls.getUsersByCityChoices, 'post', { choice1: center1, choice2: center2 });
}

export async function sendMail(payload) {
    return await makeRequest(urls.sendMail, 'post', payload);
}

export async function getUsersAskedForChanges() {
    return await makeRequest(urls.getUsersAskedForChanges, 'get');
}

export async function getUserDetails(userId) {
    return await makeRequest(urls.getUserDetails + userId, 'get');
}

export async function updateUserDetails(payload, userId) {
    return await makeRequest(urls.updateUserDetails + userId, 'put', payload);
}

export async function getAllRegTwoUsers(pageSize, pageNumber) {
    return await makeRequest(urls.getAllRegTwoUsers + '?&pageSize=' + pageSize + '&paseNumber=' + pageNumber, 'get');
}

export async function getRegTwoUserByStatus(status, pageNumber, pageSize) {
    return await makeRequest(urls.getRegTwoUserByStatus + '?status=' + status + '&pageSize=' + pageSize + '&paseNumber=' + pageNumber, 'get');
}

export async function sendRegTwoGeneralEmail(payload) {
    return await makeRequest(urls.sendRegTwoGeneralEmail, 'post', payload);
}

export async function getUserRegDetailsPart2(id) {
    return await makeRequest(urls.getUserRegDetailsPart2 + id, 'get');
}

export async function getMICR2StatusCount() {
    return await makeRequest(urls.getPart2Status, "get");
}

export async function getMICR2BStatusCount() {
    return await makeRequest(urls.getPart2BStatus, "get");
}

export async function getAllMicr2bUsers() {
    return await makeRequest(urls.getAllRegTwoBUsers+"?pageSize=9999999999", "get");
}