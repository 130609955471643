import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Login from "./views/Login/Login";
import Dashboard from "./views/Dashboard/Dashboard";
import Auth from "./Auth";
import Logout from "./views/Logout/Logout";
import ViewUser from "./views/ViewUser/ViewUser";
import RequestDetails from "./views/UpdateUser/RequestDetails";
import ViewMicrUser from "./views/Micr-2/ViewUser/ViewMicrUser";

const Router = () => (
  <Switch>
    <PublicRoute exact path="/login" component={Login} />
    <PublicRoute exact path="/">
      <Redirect to="/login" />
    </PublicRoute>

    <PrivateRoute exact path="/logout" component={Logout} />
    <PrivateRoute path="/dashboard/:menu/:submenu" component={Dashboard} />
    <PrivateRoute path="/viewuser/:userid" component = {ViewUser}/>
    <PrivateRoute path="/userDetails/:_id" component={RequestDetails}/>
    <PrivateRoute path="/micrUserDetails/:userid" component={ViewMicrUser}/>
    <PrivateRoute exact path="*">
      <Redirect to="/dashboard/home/dash" />
    </PrivateRoute>
  </Switch>
);

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.checkAuth() ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/login",
          }}
        />
      )
    }
  />
);

const PublicRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      Auth.checkAuth() === false ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: "/dashboard/home/dash",
          }}
        />
      )
    }
  />
);

export default Router;
