import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import React, { useEffect, useState } from 'react';
import { Container, Card, Row, Col, Image } from 'react-bootstrap';
import { useHistory, useParams, useLocation } from 'react-router';
import { toast } from 'react-toastify';
import { getUserProfile } from '../../../services/admin';

import moment from 'moment';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { statusColors } from '../../../services/statusColors';
import ViewEduInfo from '../../ViewUser/ViewEduInfo';
import Micr2Details from './Micr2Details';

export default function ViewMicrUser() {
    const { userid } = useParams();

    const [userData, setUserData] = useState();
    const [loaded, setLoaded] = useState(false)

    let history = useHistory();
    const location = useLocation();
    const id = location.state.id;
    const createDateFormat = (date) => {
        return moment(date).format("DD MMM YY")
    }

    const getData = async () => {
        await getUserProfile(userid).then(res => {
            if (res.status === 200) {
                toast.success("Data Loaded")
                setUserData(res.data);
                setLoaded(true)
            }
            else {
                toast.error("something went wrong")
            }
        }).catch(e => {
            toast.error("Something went wrong")
        })

    }

    const createStatus = (status) => {
        let color = statusColors(status);
        return <span class={"badge badge-" + color}>{status}</span>
    }

    useEffect(() => {
        getData();
    }, [])


    return (
        <>
            <div className="bg-bg-primary" style={{ minHeight: '100vh' }}>
                <Container className='pt-4'>
                    <Card className="p-3 border-0 rounded">
                        <FontAwesomeIcon icon={faArrowLeft} onClick={() => history.goBack()} />
                        {
                            loaded ?
                                <div className="mt-4">
                                    <h4 className="font-weight-bold">
                                        <div> Candidate Info (Reg. No. {userData.registerationNumber})</div>
                                    </h4>
                                    <Row className="my-4">
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Name: {" "}
                                                </span>
                                                <span>
                                                    {userData.name} {" "} {createStatus(userData.status)}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Application Number: {" "}
                                                </span>
                                                <span>
                                                    {userData.applicationNumber ? userData.applicationNumber : "No application Number Generated"}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Mobile Number: {" "}
                                                </span>
                                                <span>
                                                    {userData.phone}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <hr />
                                    <Row className="mt-4">
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Nationality: {" "}
                                                </span>
                                                <span>
                                                    {userData.nationality}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Date of Birth: {" "}
                                                </span>
                                                <span>
                                                    {createDateFormat(userData.dob)}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Email: {" "}
                                                </span>
                                                <span>
                                                    {userData.email}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div className="font-weight-bold">
                                                <span className="text-muted">
                                                    Gender: {" "}
                                                </span>
                                                <span>
                                                    {userData.gender}
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                                : " Loading... "}
                    </Card>
                </Container>
                <Container className='pt-4'>
                    <Card className="p-3 border-0 rounded">
                        <h5 className="font-weight-bold">
                            Transaction Details
                        </h5>
                        <div className="d-flex justify-content-between">
                            {
                                userData && userData.transactionDetails ?
                                    <>
                                        <div>Method: <span className="font-weight-bold">{userData.transactionDetails.method}</span></div>
                                        <div>Amount: <span className="font-weight-bold">{userData.transactionDetails.currency + " " + String(Number(userData.transactionDetails.amount) / 100)}</span></div>
                                        <div>Order Id: <span className="font-weight-bold">{userData.transactionDetails.orderId}</span></div>
                                    </>
                                    : "No Trasactions"
                            }
                        </div>
                    </Card>
                </Container>
                <Container className='pt-4'>
                    <Card className="p-3 border-0 rounded">
                        <h5 className="font-weight-bold">
                            Education Information
                        </h5>
                        <div>
                            {
                                userData && userData.educationQualification && userData.educationQualification.length > 0 ? <ViewEduInfo educationInfo={userData.educationQualification} /> : "No Data Avaliable"
                            }
                        </div>
                    </Card>
                </Container>
                <Container className='pt-4'>
                    <Card className="p-3 border-0 rounded">
                        <h5 className="font-weight-bold">
                            City Choices
                        </h5>
                        <div>
                            {
                                userData && userData.cityChoices && userData.cityChoices.length > 0 ? userData.cityChoices.map((data, index) => {
                                    return (
                                        <>
                                            {<span className="mr-3"> Choice {index + 1}:  <span className="font-weight-bold">{(data).toUpperCase()}</span> </span>}
                                        </>
                                    )
                                }) : "User yet to submit Center Preferences"
                            }
                        </div>
                    </Card>
                </Container>
                <Container className='pt-4 pb-5'>
                    <Card className="p-3 border-0 rounded">
                        <h5 className="font-weight-bold">
                            Personal Details
                        </h5>
                        <div className="d-flex justify-content-around mt-3 align-items-center">
                            <div className="">
                                {userData && userData.photo ? <Image src={userData.photo} style={{ height: "10rem" }} /> : "Pending Upload"}
                                <div className="text-center font-weight-bold mt-2">
                                    User Photo
                                </div>
                            </div>
                            <div>
                                {userData && userData.sign ? <Image src={userData.sign} style={{ height: "7rem" }} /> : "Pending Upload"}
                                <div className="text-center font-weight-bold mt-2">
                                    User Signature
                                </div>
                            </div>
                            <div className="text-center">
                                {userData && userData.identityProof ? <Image src={userData.identityProof} style={{ height: "10rem" }} /> : "Pending Upload"}
                                <div className="text-center font-weight-bold mt-2">
                                    User ID Proof
                                </div>
                            </div>
                        </div>
                    </Card>
                </Container>

                <Container className='pt-4'>
                    <Card className="p-3 border-0 rounded">
                        <h5 className="font-weight-bold">
                            MICR 2 Details
                        </h5>

                        <div className="d-flex justify-content-around mt-3 align-items-center">
                            <Micr2Details id={id} />
                        </div>
                    </Card>
                </Container>
            </div>
        </>
    )
}