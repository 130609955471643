import React, {useState} from 'react';
import { faPlusSquare, faHome } from '@fortawesome/free-solid-svg-icons'


import SubComponent from '../../components/SubComponent/SubComponent';
import HomeDash from './HomeDash';

export default function Home() {

    let [selectedNav, setSelectedNav] = useState(0);
    const topNavMenu = [
        {
            title: "Dash",
            url: 'dash',
            component: HomeDash
        },
        {
            title: "Requests",
            url: 'requests',
            component: HomeDash

        }
    ];
    


    return (
        <>
              <SubComponent topNavMenu={topNavMenu} selectedNav={selectedNav} setNav={(value)=>setSelectedNav(value)} /> 
        </>

    )
}