import React, {useEffect} from "react";
import { useHistory } from "react-router-dom";
import Auth from "../../Auth";
import { Redirect } from "react-router-dom";
import { toast } from "react-toastify";


const Logout = () => {
  const history = useHistory();

  const onMount = () => {
    sessionStorage.removeItem('authKey');
    history.push("/login");
    Auth.signout();
    toast.success("Logged Out!")
  };

  useEffect(() => {
    onMount();
  }, [])

  return (
    <>
      Logging Out...
      <Redirect to={{
            pathname: "/login"
          }}/>
    </>
  );
};

export default Logout;
