import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';
import XadmContainer from '../../../components/Container/Container';

import { getAllRegTwoUsers, getMICR2StatusCount } from '../../../services/admin';
import MicrUserTable from '../MicrUserTable';

export default function AllUsers() {

    const [userData, setUserData] = useState();

    const [statusData, setStatusData] = useState({});

    const getData = async () => {
        await getAllRegTwoUsers('10', '1')
            .then(res => {
                if (res.status === 200) {
                    toast.success("Fetched Records")
                    setUserData(res.data)
                }
            }).catch(e => {
                console.error(e.response.data)
                toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
            })
    }

    const getPart2StatusData = () => {
        getMICR2StatusCount().then(res => {
            if (res.status === 200) {
                setStatusData(res.data);
            }
        }).catch(e => {
            console.error(e)
            toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
        })
    }

    useEffect(() => {
        getData();
        getPart2StatusData();
    }, [])

    return (
        <>
            <Container className="mt-4 ">
                <Row className="mt-3 pt-2 rounded ">
                    {/* <h5 className='mx-2'>Students Registered for MICR part 2</h5> */}
        
                    <XadmContainer
                        content={
                            <>
                                <div className="row">
                                    <div className="col-md-3">
                                        <div className="card-counter bg-info text-white">
                                            <i className="fa fa-code-fork"></i>
                                            <span className="count-numbers">{statusData && statusData.init ? statusData.init : 0}</span>
                                            <span className="count-name">Initialized</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="card-counter danger bg-danger text-white">
                                            <i className="fa fa-ticket"></i>
                                            <span className="count-numbers">{statusData && statusData.previewed ? statusData.previewed : 0}</span>
                                            <span className="count-name">Previewed</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="card-counter bg-success text-white">
                                            <i className="fa fa-database"></i>
                                            <span className="count-numbers">{statusData && statusData.verified ? statusData.verified : 0}</span>
                                            <span className="count-name">Verified</span>
                                        </div>
                                    </div>

                                    <div className="col-md-3">
                                        <div className="card-counter bg-primary text-white">
                                            <i className="fa fa-users"></i>
                                            <span className="count-numbers">{statusData && statusData.submitted ? statusData.submitted : 0}</span>
                                            <span className="count-name">Submitted</span>
                                        </div>
                                    </div>
                                </div>
                            </>
                        }
                    />

                </Row>
                <Row className="bg-white mt-3 pt-2 rounded">
                    <Col>
                        {
                            userData && userData.users ?
                                <MicrUserTable rows={userData.users} /> : "Please Wait"
                        }
                    </Col>
                </Row>

            </Container>

        </>
    )

}