import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import Select from 'react-select';
import { toast } from 'react-toastify';

import { getRegTwoUserByStatus, getUserByStatus } from '../../../services/admin';
import MicrUserTable from '../MicrUserTable';
import moment from 'moment';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';


export default function ShowByStatus() {
    const [userData, setUserData] = useState({
        users: []
    });
    const [selectedUserStatus, setUserStatus] = useState();
    const [pageNum, setPageNum] = useState(1);
    const userStatuses = [
        {
            label: "Initalized",
            value: "init"
        },
        {
            label: "Previewed",
            value: "previewed"
        },
        {
            label: "Verified Users",
            value: "verified"
        },
        {
            label: "Submitted",
            value: "submitted"
        }
    ]
    const [selectedStatus, setSelectedStatus] = useState(userStatuses[0].value);

    const [downloadCsvFormat, setDownloadCSVFormat] = useState([]);



    const getUserDataByStatus = async (status) => {
        await getRegTwoUserByStatus(status, pageNum, 10)
            .then(res => {
                if (res.status === 200) {
                    setUserData(res.data)
                    if (res.data.users.length > 0) {
                        toast.success("Fetched Records")
                    }
                }
            }).catch(e => {
                console.error(e.response.data)
                toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
            })
    }


    const changeUserStatus = async (status) => {
        setSelectedStatus(status.value)
        getUserDataByStatus(status.value);
    }
    useEffect(() => {
        getUserDataByStatus('submitted');
    }, [])


    const createDownloadCSVFormat = (statusdata) => {
        let csvFileData;
        if (statusdata && statusdata.users.length > 0) {
            csvFileData = statusdata.users.map((data, index) => {
                return {
                    Name: data.user.name,
                    "Registration Number": data.user.registerationNumber,
                    Email: data.user.email,
                    Phone: data.user.phone,
                    Status: data.status,
                    "City 1": data.cityChoices || data.user.cityChoices[0] || "Not Selected",
                    "Date of Birth": data.user.dob ? moment(data.user.dob).format("Do MMMM YYYY") : "Not Available",
                    "Fee Amount": data.transactionDetails ? (data.transactionDetails.currency + " " + String(data.transactionDetails.amount / 100)) : "0",
                    "Razorpay Transaction Id": data.transactionDetails ? (data.transactionDetails.orderId) : "NA",
                    "Trans. Date and Time": data.transactionDetails ? moment(data.transactionDetails.updatedAt).format("Do MMMM YYYY hh:mm:ss a") : "NA",
                    "Application Number": data.applicationNumber || "Not Generated",
                    "Approved Training": data.approvedTraining || "Not Selected",
                    "Passing Year": data.passingYear || "Not Selected",
                    "Remarks": data.remarks || "-"
                }

            });
        }
        setDownloadCSVFormat(csvFileData);
    }

    const downloadCsvData = async () => {
        await getRegTwoUserByStatus(selectedStatus, 1, 999999999)
            .then(res => {
                if (res.status === 200) {
                    createDownloadCSVFormat(res.data)
                }
            }).catch(e => {
                console.error(e.response.data)
                toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
            })
    }

    useEffect(() => {
        setDownloadCSVFormat([]);
        downloadCsvData()
    }, [selectedStatus])

    return (
        <>
            <Container className="mt-4 ">
                <Row>
                    <Col md={4}>
                        <Select
                            options={userStatuses}
                            onChange={changeUserStatus}
                            defaultValue={userStatuses[0]}
                        />



                    </Col>
                    <Col md={4}>
                        {downloadCsvFormat.length > 0 &&
                            <CSVLink
                                data={downloadCsvFormat}
                                filename={selectedStatus + ".csv"}
                            >
                                <Button> Download CSV <FontAwesomeIcon icon={faDownload} /> </Button>
                            </CSVLink>
                        }
                    </Col>
                </Row>
                <Row className="bg-white mt-3 pt-2 rounded">
                    <Col>
                        {
                            userData && userData.users && userData.users.length > 0 ?
                                <MicrUserTable rows={userData.users} heading="User Data" /> : "No Data"
                        }
                    </Col>
                </Row>
            </Container>
        </>
    )
}