import React, { useEffect, useState } from 'react'
import { Container, Card } from 'react-bootstrap';
import { getUserRegDetailsPart2 } from '../../../services/admin';
import moment from 'moment';

export default function Micr2Details({ id }) {

    const [userData, setUserData] = useState({});
    const getData = async () => {
        await getUserRegDetailsPart2(id)
            .then((res) => {
                if (res.status === 200) {
                    console.log(res.data, "micr2 user data");
                    setUserData(res.data);
                }
            })
            .catch((err) => {
                console.error(err);
            })
    }
    const createDate = (date) => {
        return moment(date).format("Do MMM YY")
    }

    useEffect(() => {
        getData();
    }, []);
    return (
        <>
            <div className="d-flex flex-column">
                <Card className="p-3 border-0 rounded">
                    <h6 className="font-weight-bold">
                        User Details
                    </h6>
                    <div className="d-flex justify-content-between">
                        <div><span className="font-weight-bold">{userData.remarks}</span></div>
                        <div>Created at:<span className="font-weight-bold">{createDate(userData.createdAt)}</span></div>
                        <div>City Choices: <span className="font-weight-bold">{userData.cityChoices}</span></div>
                    </div>
                </Card>
                <Card className="p-3 border-0 rounded">
                    <h6 className="font-weight-bold">
                        Transaction Details
                    </h6>
                    <div className="d-flex justify-content-between">
                        {
                            userData && userData.transactionId ?
                                <>
                                    <div>Method: <span className="font-weight-bold">{userData.transactionId.method}</span></div>
                                    <div>Amount: <span className="font-weight-bold">{userData.transactionId.currency + " " + String(Number(userData.transactionId.amount) / 100)}</span></div>
                                    <div>Order Id: <span className="font-weight-bold">{userData.transactionId.orderId}</span></div>
                                </>
                                : "No Trasactions"
                        }
                    </div>
                </Card>
            </div>
        </>
    )
}

/*
{
    "passingYear": true,
    "approvedTraining": true,
    "status": "part_2_submitted",
    "_id": "62873d707d368f15a745f0e5",
    "user": "619363dddc37dc481d88a94f",
    "createdAt": "2022-05-20T07:04:16.021Z",
    "updatedAt": "2022-05-20T13:04:41.824Z",
    "remarks": "Name: Jiyaul Mustaffa",
    "transactionId": {
        "method": "razorpay",
        "currency": "INR",
        "status": "completed",
        "_id": "628767037d368f15a745f11e",
        "user": "62873d707d368f15a745f0e5",
        "registerationNumber": "PR0XB8HSH5",
        "exam": "627be018104b4c6c7e517488",
        "amount": 2050000,
        "orderId": "order_JXRRF8i8WMhHgf",
        "createdAt": "2022-05-20T10:01:39.053Z",
        "updatedAt": "2022-05-20T10:02:41.811Z",
        "paymentId": "pay_JXRSB888XHOInf",
        "paymentSignature": "1362a741f7a4fa1951750b32392f35f37010fbb6bbcc861006aad862fd11cfda"
    },
    "cityChoices": "chennai"
}
*/