import React from 'react'

const Micr2bStats = (props) => {
    return (
        <>
            <div className="col-md-3">
                <div className="card-counter bg-success text-white">
                    <i className="fa fa-database"></i>
                    <span className="count-numbers">{props.data && props.data.submitted ? props.data.submitted : 0}</span>
                    <span className="count-name">Submitted</span>
                </div>
            </div>
            <div className="col-md-3">
                <div className="card-counter bg-primary text-white">
                    <i className="fa fa-users"></i>
                    <span className="count-numbers">{props.data && props.data.init ? props.data.init : 0}</span>
                    {/* <span className="count-numbers">{stats && stats.verifiedCount ? stats.verifiedCount : 0}</span> */}
                    <span className="count-name">Init</span>
                </div>
            </div>
        </>
    )
}

export default Micr2bStats