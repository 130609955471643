import React from 'react';


import { Nav, Image } from "react-bootstrap";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


import './Sidebar.css';
import nixadmLogo from '../../assets/icons/edit.png'
import { faSignOutAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router';

export default function XadmSideBar(props) {
    let history = useHistory();
    return (
        <>
            <Nav defaultActiveKey="0" className="flex-column xadm-dashboard-sub-nav bg-white">
                <div className="p-4 mb-2">
                    <Image src={nixadmLogo} className="xadm-dash-logo" />
                    <span className="text-primary font-weight-bold ml-2">NIXARG</span>
                </div>
                {
                    props.menuData.map((data, index) => {
                        if (index == props.selected) {
                            return (
                                <Nav.Link className="subNavLink text-primary xadm-nav-selected" key={index}>
                                    <span>
                                        <FontAwesomeIcon icon={data.icon} className="mr-3" />
                                        <span className="font-weight-bold text-primary">
                                            {data.title}
                                        </span>
                                    </span>
                                </Nav.Link>

                            )

                        }
                        else {
                            return (
                                <Nav.Link className="subNavLink text-muted" key={index} onClick={() => props.handler(index)}>
                                    <span>
                                        <FontAwesomeIcon icon={data.icon} className="mr-3" />
                                        <span>
                                            {data.title}
                                        </span>
                                    </span>
                                </Nav.Link>

                            )
                        }
                    })
                }
                <div className="text-center mt-3 font-weight-bold">
                    <span className="text-secondary" onClick={()=>history.push('/logout')}>
                        <FontAwesomeIcon icon={faSignOutAlt}  /> Logout
                    </span>
                </div>
            </Nav>


        </>

    )
}