export function statusColors(status){
    let color = "primary";

  switch(status){
    case 'submitted': color = 'success'; break;
    case 'verified': color = 'primary'; break;
    case 'pending': color = 'light'; break;
    case 'eduinfo': color = 'warning'; break;
    case 'geninfo': color = 'info'; break;
    default: color = 'danger';break;
  }

  return color
}