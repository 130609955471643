import { faCheck, faCheckCircle, faCircleMark, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import React, { useState } from 'react'
import { useEffect } from 'react';
import { Container, Row, Col, Table, Button } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getAllMicr2bUsers, getMICR2BStatusCount } from '../../services/admin'
import Micr2bStats from './micr2b.stats'
import { useHistory } from "react-router-dom"
import { CSVLink } from 'react-csv';
import { faDownload } from '@fortawesome/free-solid-svg-icons';


const Micr2bIndex = () => {
    const history = useHistory();

    const [statusCount, setStatusCount] = useState();
    const [userData, setUserData] = useState();

    const [downloadCsvFormat, setDownloadCSVFormat] = useState([]);

    const createDownloadCSVFormat = (users) => {
        let csvFileData;
        if (users.length > 0) {
            csvFileData = users.map((data, index) => {
                return {
                    Name: data.user.name,
                    "Registration Number": data.user.registerationNumber,
                    Email: data.user.email,
                    Phone: data.user.phone,
                    Status: data.status,
                    "Date of Birth": data.user.dob ? moment(data.user.dob).format("DD MMM YYYY") : "Not Available",
                    "Selected City": data.cityChoices || "--",
                    "Transaction Id": data.transactionId || "--",
                    "Qualification from": data.country || "--",
                    "Application Number": data.exam && data.exam.applicationNumber || "--",
                }

            });
        }
        setDownloadCSVFormat(csvFileData);
    }

    const onMount = async () => {
        await getMICR2BStatusCount()
            .then(res => {
                if (res.status === 200) {
                    setStatusCount(res.data)
                }
            }).catch(e => {
                console.error(e.response.data)
                toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
            })
    }

    const getAll2bUsers = async () => {
        await getAllMicr2bUsers()
            .then(res => {
                if (res.status === 200) {
                    createDownloadCSVFormat(res.data.users)
                    setUserData(res.data)
                }
            }).catch(e => {
                console.error(e.response.data)
                toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
            })
    }

    useEffect(() => {
        onMount();
        getAll2bUsers();
    }, [])
    return (
        <>
            <div>
                <div className="d-flex justify-content-around align-item-center pt-4">
                    <Micr2bStats data={statusCount} />
                </div>
                <Container className="mt-4 ">
                <Row>
                  
                    <Col md={4}>
                        {downloadCsvFormat.length > 0 &&
                            <CSVLink
                                data={downloadCsvFormat}
                                filename={"micr2bdata.csv"}
                            >
                                <Button> Download CSV <FontAwesomeIcon icon={faDownload} /> </Button>
                            </CSVLink>
                        }
                    </Col>
                </Row>
                    <Row className="bg-white mt-3 pt-2 rounded">
                        <Col>
                            {
                                userData && userData.users ?
                                    <Table size="sm" responsive striped>
                                        <thead className='fs-12'>
                                            <th>
                                                Name
                                            </th>
                                            <th>
                                                Email
                                            </th>
                                            <th>
                                                Reg No.
                                            </th>

                                            <th>
                                                Phone
                                            </th>
                                            <th>
                                                Created On
                                            </th>
                                            <th>
                                                Fee Status
                                            </th>
                                            <th>
                                                City Selected
                                            </th>
                                            <th>
                                                App No.
                                            </th>
                                            <th>
                                                Nationality
                                            </th>
                                            <th>
                                                Action
                                            </th>
                                        </thead>
                                        <tbody>
                                            {userData.users.map((user) => {
                                                return (
                                                    <tr className='fs-12'>
                                                        <td>{user && user.user.name}</td>
                                                        <td>{user && user.user.email}</td>
                                                        <td className='font-weight-bold'>{user && user.user.registerationNumber}</td>
                                                        <td>{user && user.user.phone}</td>
                                                        <td>{user && moment(user.createdOn).format("DD-MMM-YYYY")}</td>
                                                        <td>{user && user.status === "part_2_submitted" ? <FontAwesomeIcon icon={faCheckCircle} className="text-success" /> : <FontAwesomeIcon icon="fa-solid fa-xmark" className="text-danger" />}</td>
                                                        <td>{user && user.cityChoices ? user.cityChoices : "--"}</td>
                                                        <td>{user && user.exam && user.exam.applicationNumber ? user.exam.applicationNumber : "--"}</td>
                                                        <td>{user && user.country ? user.country : "--"}</td>
                                                        <td><FontAwesomeIcon icon={faEye} onClick={() => history.push("/viewuser/" + user.user._id)} /></td>
                                                    </tr>
                                                )
                                            })}
                                        </tbody>
                                    </Table> : "No data found"
                            }
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    )
}

export default Micr2bIndex