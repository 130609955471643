import React, { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Card } from "react-bootstrap";
import Select from "react-select";
import { toast } from "react-toastify";
import { sendMail } from "../../services/admin";

function SendEmailBox() {
  const [selectedUserStatus, setUserStatus] = useState();
  const [subject, setSubject] = useState();
  const [content, setContent] = useState();
  const [files, setFiles] = useState()
  const userStatuses = [
    {
      label: "Pending",
      value: "pending",
    },
    {
      label: "General Info",
      value: "geninfo",
    },
    {
      label: "Education Info",
      value: "eduinfo",
    },
    {
      label: "Uploads",
      value: "uploaded",
    },
    {
      label: "Verified Users",
      value: "verified",
    },
    {
      label: "Submitted",
      value: "submitted",
    },
  ];

  const changeUserStatus = (status) => {
    setUserStatus(status.value);
  };

  const addFiles = (files) => {
    setFiles(files.target.files)
  }
  const sendEmail = async () => {
    const Form = new FormData();
    Form.append("status", selectedUserStatus);
    Form.append("subject", subject);
    Form.append("content", content);
    if(files) {
      for (let file of files) {
        Form.append("attachment", file);
      }
    }
    console.log(Form)
    await sendMail(Form)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Email Send Successfully");
        }
      })
      .catch((e) => {
        console.error(e.response.data);
        toast.error(
          "Something went Wrong!" + e.response.status
            ? e.response.status
            : " - "
        );
      });
  };
  return (
    <>
      <Container className="mt-4">
        <Card className="p-5 border-0" style={{ borderRadius: "10px" }}>
          <div className="">
            <label htmlFor="select" className="font-weight-bold">
              User State
            </label>
            <Select options={userStatuses} onChange={changeUserStatus} />
            <label htmlFor="subject" className="font-weight-bold mt-4">
              Subject
            </label>
            <Form.Control
              type="text"
              placeholder="Enter Subject"
              aria-describedby="passwordHelpBlock"
              onChange={(e) => setSubject(e.target.value)}
            />
            <label htmlFor="body" className="font-weight-bold mt-4">
              Email Body
            </label>
            <Form.Control
              as="textarea"
              placeholder="Leave a message here"
              style={{ height: "100px" }}
              onChange={(e) => setContent(e.target.value)}
            />
            <label htmlFor="body" className="font-weight-bold mt-4">
              Attach Files
            </label>
            <Form.Group controlId="formFileMultiple" className="mb-3">
              <Form.Control type="file" multiple 
              onChange={addFiles} />
            </Form.Group>
            <div className="d-flex justify-content-center">
              <Button
                variant="danger"
                onClick={sendEmail}
                className="mt-2 text-center"
              >
                Send Email
              </Button>
            </div>
          </div>
        </Card>
      </Container>
    </>
  );
}

export default SendEmailBox;
