import React from 'react';


import { Navbar, Nav } from "react-bootstrap";

import './Navbar.css';

export default function XadmNavbar (props){
return (

<>

<Navbar bg="white" variant="light" className="xadm-navbar">
    <Nav className="mr-auto">

    {props.menuData.map((data,index)=>{
            if(index == props.selected){
                return (
                    <Nav.Link href={data.url} className="font-weight-bold" active>{data.title}</Nav.Link>
                )
            }
            else{
                return (
                    <Nav.Link href={data.url} onClick={()=>props.setNav(index)}>{data.title}</Nav.Link>
                )
            }
        })
    }

    </Nav>
  </Navbar>
</>

)}