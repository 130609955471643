import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import moment from 'moment';
import { Table ,Container ,Row,Col} from "react-bootstrap";
import { getUsersAskedForChanges } from '../../services/admin'
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";

export default function UserTable() {

  const [userData, setUserData] = useState([]);
  const history = useHistory();
  const getUserRequests = async () => {
    await getUsersAskedForChanges()
      .then((res) => {
        if (res.status == 200) {
          toast.success("Data Fetched");
          setUserData(res.data)
        }
      })
      .catch(e => {
        console.error(e.response.data)
        toast.error("Something went Wrong!" + e.response.status ? e.response.status : " - ")
      })
  }

  const createDate = (date) => {
    return moment(date).format("Do MMM YY")
  }

  useEffect(() => {
    getUserRequests();
  }, [])

  return (
    <>
      <Container className="mt-4 ">
        <Row className="bg-white mt-3 pt-2 rounded">
          <Col>        
          <Table size="sm" responsive striped>
            <thead>
              <th></th>
              <th>
                Name
              </th>
              <th>
                Email
              </th>
              <th>
                Phone
              </th>
              <th>
                Id
              </th>
              <th>
                Status
              </th>
              <th>
                Remarks
              </th>
              <th>
                Created On
              </th>
            </thead>
            <tbody>
              {userData.map((data, index) => {
                return (
                  <tr>
                    <td>
                    <FontAwesomeIcon icon={faEye} onClick={()=>history.push('/userDetails/'+data.user._id  , {remarks:data.remarks} )}/>
                    </td>
                    <td className="font-weight-bold">
                      {data.user.name}
                    </td>
                    <td>
                      {data.user.email}
                    </td>
                    <td>
                      {data.user.phone}
                    </td>
                    <td>
                      {data.user._id}
                    </td>
                    <td>
                      {data.status}
                    </td>
                    <td>
                      {data.remarks}
                    </td>
                    <td>
                      {createDate(data.createdAt)}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </Table>

          </Col>
        </Row>
      </Container>


    </>
  )
}