import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { toast } from 'react-toastify';

import { getUsers } from '../../../services/admin';

import DataTable from '../DataTable/DataTable';
import UserStats from '../UserStats/UserStats';

export default function AllUsers() {

  const [userData, setUserData] = useState();

    const onMount = async ()=>{
        await getUsers('10', "1")
        .then(res=>{
            if(res.status === 200){
                toast.success("Fetched Records")
                setUserData(res.data)
            }
        }).catch(e=>{
            console.error(e.response.data)
            toast.error("Something went Wrong!" + e.response.status?e.response.status:" - ")
        })
    }


useEffect(()=>{
    onMount();
}, [])

    return (
        <>
        <div className="mt-4">
            <UserStats stats={userData}/>
        </div>
            <Container className="mt-4 ">
                <Row className="bg-white mt-3 pt-2 rounded">
                <Col>
                    {
                        userData && userData.users?
                    <DataTable rows={userData.users}  showStatus={true} showApplicationNumber={false} heading="User Data"/>: "Please Wait"

                    }
                </Col>
                </Row>

            </Container>

        </>
    )

}