import React, {useState} from 'react';
import { faPlusSquare, faHome } from '@fortawesome/free-solid-svg-icons'


import SubComponent from '../../components/SubComponent/SubComponent';
import SendEmailBox from './SendEmailBox';

function SendEmail() {
    let [selectedNav, setSelectedNav] = useState(0);
    const topNavMenu = [
        {
            title: "Send By Status",
            url: 'sendmail',
            component: SendEmailBox
        }
    ];
    


    return (
        <>
              <SubComponent topNavMenu={topNavMenu} selectedNav={selectedNav} setNav={(value)=>setSelectedNav(value)} /> 
        </>

    )
}

export default SendEmail
