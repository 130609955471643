import React ,{useState} from 'react'
import SubComponent from '../../components/SubComponent/SubComponent';
import UserTable from './UserTable';

export default function UpdateUser() {
    let [selectedNav, setSelectedNav] = useState(0);
    const topNavMenu = [
        {
            title: "Requests",
            url: 'request',
            component: UserTable
        }
    ];
  return (
    <>
        <SubComponent topNavMenu={topNavMenu} selectedNav={selectedNav} setNav={(value)=>setSelectedNav(value)} />
    </>
  )
}