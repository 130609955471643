import React ,{useState} from 'react'
import SubComponent from '../../components/SubComponent/SubComponent'
import MicrUserTable from './MicrUserTable';
import AllUsers from './AllUser/AllUser';
import ShowByStatus from './ShowByStatus/ShowByStatus';
import SendEmail from './SendEmail/SendEmail';

export default function MicrPart2() {
    const [selectedNav, setSelectedNav] = useState(0);
    const topNavMenu = [
        {
            title: "All Users",
            component: AllUsers,
            url: "all"
        },
        {
            title: "View By Status",
            component: ShowByStatus,
            url: "show-micr-bystatus"

        },
        {
            title: "Send Emails",
            component: SendEmail,
            url: "send-emails"
        }
    ];
    return (
        <>
            <SubComponent topNavMenu={topNavMenu} selectedNav={selectedNav} setNav={(value) => { setSelectedNav(value) }} />
        </>
    )
}