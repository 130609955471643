import React, { useState, useEffect } from "react";
import DataTable from "../DataTable/DataTable";
import axios from "axios";

import XadmContainer from "../../../components/Container/Container";
import "./UserStats.css";
import { Card } from "react-bootstrap";
export default function UserStats({stats}) {
  
  return (
    <>
      <XadmContainer
        content={
          <>
              <div className="row">
                <div className="col-md-3">
                  <div className="card-counter bg-info text-white">
                    <i className="fa fa-code-fork"></i>
                    <span className="count-numbers">{stats&&stats.totalUsers?stats.totalUsers: 0}</span>
                    <span className="count-name">Total Users</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter danger bg-danger text-white">
                    <i className="fa fa-ticket"></i>
                    <span className="count-numbers">{stats&&stats.pendingCount?stats.pendingCount: 0}</span>
                    <span className="count-name">Pending</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter bg-success text-white">
                    <i className="fa fa-database"></i>
                    <span className="count-numbers">{stats&&stats.submittedCount? stats.submittedCount : 0}</span>
                    <span className="count-name">Submitted</span>
                  </div>
                </div>

                <div className="col-md-3">
                  <div className="card-counter bg-primary text-white">
                    <i className="fa fa-users"></i>
                    <span className="count-numbers">{stats&&stats.verifiedCount?stats.verifiedCount:0}</span>
                    <span className="count-name">Verified</span>
                  </div>
                </div>
              </div>
          </>
        }
      />
    </>
  );
}
