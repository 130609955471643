import React, { useEffect, useState } from "react";

import { useParams, useHistory } from "react-router";

// views
import Home from "../Home/Home";
import UserData from "../UserData/UserData";

// components
import XadmSidebar from "../../components/Sidebar/Sidebar";
// Icons

// CSS
import "./Dashboard.css";
import "../../assets/css/form-components.css";

import { faPlusSquare, faHome, faSignOutAlt, faEnvelope, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import Logout from "../Logout/Logout";
import SwitchComponent from "../../components/SwitchComponent/SwitchComponent";
import SendEmail from "../SendEmail/SendEmail";
import UpdateUser from "../UpdateUser/UpdateUser.index";
import MicrPart2 from "../Micr-2/Micr2.index";
import Micr2bIndex from "../Micr-2b/micr2b.index";



export default function Dashboard() {

  const { menu } = useParams();

  let history = useHistory();

  const [selectedNav, setselectedNav] = useState(0)

  const sideMenudata = [
    {
      title: "Dashboard",
      icon: faHome,
      url: 'home',
      defaultSubUrl: 'dash',
      component: Home
    },
    {
      title: "Candidates",
      icon: faPlusSquare,
      url: 'user',
      defaultSubUrl: 'all',
      component: UserData
    },
    {
      title: "Send Email",
      icon: faEnvelope,
      url: "email",
      defaultSubUrl: 'sendmail',
      component: SendEmail
    },
    {
      title: "Updation Request",
      icon: faEnvelope,
      url: "update-requests",
      defaultSubUrl: 'requests',
      component: UpdateUser
    },
    {
      title: "MICR Part 2",
      icon: faPlusCircle,
      url: "micr-2",
      defaultSubUrl: 'all',
      component: MicrPart2
    },
    {
      title: "MICR Part 2b",
      icon: faPlusCircle,
      url: "micr-2",
      defaultSubUrl: 'all',
      component: Micr2bIndex
    }
  ];

  function handler(value) {
    setselectedNav(value);
    history.push("/dashboard/" + sideMenudata[value].url + "/" + sideMenudata[value].defaultSubUrl);
  }

  function onMount() {
    switch (menu) {
      case "home": {
        setselectedNav(0);
        break;
      }
      case "user": {
        setselectedNav(1);
        break;
      }
      case "email": {
        setselectedNav(2);
        break;
      }
      case "update-requests": {
        setselectedNav(3);
        break;
      }
      case "micr-2": {
        setselectedNav(4);
        break;
      }
      default: {
        setselectedNav(0);
        history.push("/dashboard/home");
      }
    }
  }
  useEffect(() => {
    onMount();
  }, [])

  return (
    <div className="xadm-dashboard-bg">
      <XadmSidebar
        menuData={sideMenudata}
        handler={handler}
        selected={selectedNav}
      />
      <div className="xadm-main-area bg-bg-primary">
        <SwitchComponent component={sideMenudata[selectedNav].component} />
      </div>
    </div>
  );

}
