import React, {useEffect, useState} from "react";
import { useParams } from "react-router";

// components
import SubComponent from "../../components/SubComponent/SubComponent";

// views
import AllUsers from "./AllUsers/AllUsers";
import RegisteredUsers from "./RegisteredUsers/RegisteredUsers";
import VerifiedUsers from "./VerifiedUsers/VerifiedUsers";

import ViewRecordByCenters from "./ViewRecordsByCenters/ViewRecordByCenters";

export default function UserData() {

  let {menu, submenu} = useParams();
  let [selectedNav, setSelectedNav] = useState(0);

    const topNavMenu = [
        {
            title: "All Users",
            component: AllUsers,
            url: "all"
        },
        // {
        //     title: "Registered",
        //     component: RegisteredUsers,
        //     url: "registered"

        // },
        {
            title: "View By Status",
            component: VerifiedUsers,
            url: "showbystatus"

        },
        {
            title: "View By Centers",
            component: ViewRecordByCenters,
            url: "showbycity"
        }
    ];

    return (
        <>
           <SubComponent topNavMenu={topNavMenu} selectedNav={selectedNav} setNav={(value)=>setSelectedNav(value)} /> 
        </>

    )
}
