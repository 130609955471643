const urls = {
    getUsers: "/admin/getUsers",
    getSubmittedUsers: '/admin/getSubmittedUsers',
    getUserProfile: '/admin/getUser/',
    getUserByStatus: '/admin/getUserByStatus',
    getUserStats: '/admin/getUserStats',
    getCenters: "/users/getCenterList",
    getUsersByCityChoices: "/admin/getUsersByCityChoices",
    sendMail: '/admin/sendGeneralEmail',
    getUsersAskedForChanges: '/admin/getUsersAskedForChanges',
    getUserDetails: '/admin/getUserDetails/',
    updateUserDetails: '/admin/updateUserDetails/',
    getRegTwoUserByStatus: '/admin/getRegTwoUserByStatus',
    getAllRegTwoUsers: '/admin/getAllRegTwoUsers',
    sendRegTwoGeneralEmail: '/admin/sendRegTwoGeneralEmail',
    getUserRegDetailsPart2: '/admin/getUserRegDetailsPart2/',
    getPart2Status: "/admin/getPart2Status",

    getPart2BStatus: "/admin/getPart2BStatus",
    getAllRegTwoBUsers: "/admin/getAllRegTwoBUsers"
}

export default urls;