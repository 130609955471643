import React, { useEffect, useState } from 'react'
import { Row, Col, Table, Container } from 'react-bootstrap'
import { getRegTwoUserByStatus } from '../../services/admin';
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from 'react-router-dom';

export default function MicrUserTable({rows}) {

    const [pageNumber, setPageNumber] = useState(1);
    const [userData, setUserData] = useState([]);
    const history = useHistory();

    const createDate = (date) => {
        return moment(date).format("Do MMM YY")
    }

    console.log(rows , "rows");
    return (
        <>
            <Container className="mt-4 ">
                <Row className="bg-white mt-3 pt-2 rounded">
                    <Col>
                        <Table size="sm" responsive striped>
                            <thead>
                                <th></th>
                                <th>
                                    Name
                                </th>
                                <th>
                                    Email
                                </th>
                                <th>
                                    Reg No.
                                </th>

                                <th>
                                    Phone
                                </th>
                                <th>
                                    Created On
                                </th>
                                <th>Status</th>
                                <th>
                                    Fee Status
                                </th>
                                <th></th>
                            </thead>
                            <tbody>
                                {
                                    rows.map((data) => {
                                        return (
                                            <tr>
                                                {/* <td> <FontAwesomeIcon icon={faEye} onClick={() => history.push('/micrUserDetails/' + data.user._id , {micrId :data._id})} /></td> */}
                                                <td> <FontAwesomeIcon icon={faEye} onClick={() => history.push('/micrUserDetails/'+data.user._id  , {id:data._id} )} /></td>
                                                <td>{data.user.name}</td>
                                                <td>{data.user.email}</td>
                                                <td>{data.user.registerationNumber}</td>
                                                <td>{data.user.phone}</td>
                                                <td>{createDate(data.createdAt)}</td>
                                                <td>{data.status}</td>
                                                <td>{data.transactionDetails ? "Paid" : "Unpaid"}</td>
                                                <td>{data._id}</td>
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Container>
        </>
    )
}