import React from 'react';

import { Container } from "react-bootstrap";

import "./Container.css";
export default function XadmContainer(props){


    return (
        <Container className="xadm-container text-left">
            {props.content}
        </Container>
    )

}